import { graphql } from 'gatsby';
import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import {device} from '../styles/Breakpoints'
import { Link } from 'gatsby';

import HeaderAndMenu from '../components/HeaderAndMenu';
import Footer from '../components/Footer';
import MyMarquee from '../components/Marquee'
import Cursor from '../components/Cursor';

import BlockContent from '@sanity/block-content-to-react';
import { Helmet } from "react-helmet";

import SwiperCore, { Navigation, Lazy } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';

SwiperCore.use([Navigation, Lazy])

const GalleryPageStyles = styled.main`
    position: relative;
    min-height: 100vh; //30px is marquee height
    padding: 0 48px;
    @media ${device.$small} {
      padding: 120px 16px 0;
      display: flex;
      align-items: center;
      flex-direction: column-reverse;
      height: auto;
      padding-bottom: 48px;
    }

    .swiper-container {
      max-width: calc( 100vw - 96px );
      height: 70vh;
      top: 50vh;
      transform: translateY(-50%);
      @media ${device.$small} {
        top: initial;
        transform: initial;
        width: 100%;
        height: auto;
        padding-bottom: 48px;
      }
      .swiper-wrapper {
        max-height: 100%;
        transition-timing-function: cubic-bezier(.19,.99,.78,.96); 
        
      }
      .swiper-slide {
        padding: 0 25%;
        cursor: pointer;
        height: 100%;
        .vimeo-slide {
          height: 100%;
          display: flex;
          align-items: center;
          div {
            width: 100%;
          }
          p {
            display: none;
          }
          iframe {
            max-width: 100%;
          }
        }
        .video-slide {
          height: 100%;
          display: flex;
          align-items: center;
          video {
            width: 100%;
            object-fit: cover;
          }
          mux-player {
            object-fit: cover;
          }
        }
        @media ${device.$small} {
          padding: 0;
        }
      }
      .gatsby-image-wrapper {
        max-height: 100%;
        max-width: 680px;
      }
      .swiper-button-prev {
        left: calc(25% - 48px);
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 512 512' style='enable-background:new 0 0 512 512;' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cpath d='M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068 c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557 l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104 c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z'/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center;
        transform: rotate(180deg);
        &:after {
          display: none;
        }
        @media ${device.$small} {
          left: 2px;
        }
      }
      .swiper-button-next {
        right: calc(25% - 48px);
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 512 512' style='enable-background:new 0 0 512 512;' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cpath d='M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068 c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557 l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104 c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z'/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center;
        &:after {
          display: none;
        }
        @media ${device.$small} {
          right: 0;
        }
      }
      .swiper-button-prev,
      .swiper-button-next {
        position: absolute;
        top: 50%;
        width: calc(44px / 44 * 27);
        height: 44px;
        margin-top: calc(-1 * 44px / 2);
        z-index: 10;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        @media ${device.$small} {
          top: initial;
          bottom: 0;
        }                
      }
    }

    .gallery__title-bar {
      position: absolute;
      right: 12px;
      top: 72vh;
      width: 310px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      text-transform: uppercase;
      padding-right: 24px;
      z-index: 5;
      @media ${device.$small} {
        position: relative;
        right: initial;
        bottom: initial;
        top: initial;
        width: 100%;
        margin-top: 24px;
      }
      .back-button {
        margin-bottom: 12px;
        font-weight: normal;
        font-size: 10px;
        display: flex;
        z-index: 20;
        &--desktop {
          @media ${device.$small} {
            display: none;
          }
        }
        &--mobile {
          display: none;
          @media ${device.$small} {
            display: flex;
            z-index: inherit;
            margin: 12px auto;
            font-size: 12px;
          }
        }
        @media ${device.$small} {
          z-index: inherit;
          margin-top: 12px;
          font-size: 12px;
        }
        &:hover {
          font-weight: bold;
        }
        .left-arrow {
          height: 8px;
          width: 8px;
          margin-right: 6px;
          background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.20024 0.0829432C4.31083 0.193534 4.31083 0.372838 4.20024 0.483428L0.966857 3.71681H7.71681C7.87321 3.71681 8 3.8436 8 4C8 4.1564 7.87321 4.28319 7.71681 4.28319H0.966857L4.20024 7.51657C4.31083 7.62716 4.31083 7.80647 4.20024 7.91706C4.08965 8.02765 3.91035 8.02765 3.79976 7.91706L0.0829432 4.20024C-0.0276477 4.08965 -0.0276477 3.91035 0.0829432 3.79976L3.79976 0.0829432C3.91035 -0.0276477 4.08965 -0.0276477 4.20024 0.0829432Z' fill='black'/%3E%3C/svg%3E%0A");
          @media ${device.$small} {
            margin-top: 2px;
          }
        }
      }
      .gallery-title {
        text-align: left;
        font-size:24px;
        font-weight: bold;
        @media ${device.$small} {
          font-size: 32px;
          margin-bottom: 8px;
        }
      }
      .gallery-role {
        font-size:14px;
        font-weight: bold;
      }
      .gallery-credits {
        font-size: 9px;
        text-transform: none;
        @media ${device.$small} {
          margin-bottom: 24px;
        }
      }
    }

    .gallery-image {
      cursor: pointer;
    }

    .lightbox {
      display: none;
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      justify-content: center;
      align-items: center;
      z-index: 50;
      &__active {
        display: flex;
      }
      &__image {
        height: 90%;
        width: 90%;
        z-index: 2;
        .spotlight-image {
          display: block;
          margin: 0 auto;
          height: 100%;
          max-width: 100%;
        }
        @media ${device.$small} {
          height: auto;
          width: calc(100vw - 12px);
        }
      }
      .mask {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.6);
        .close-mask {
          cursor: pointer;
          z-index: 50;
          height: 48px;
          width: 48px;
          position: absolute;
          top: 48px;
          right: 48px;
          background-position: center;
          background-size: cover;
          background-image: url("data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24.5186 1L0.598389 25' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M0.598389 1L24.5186 25' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
          @media ${device.$small} {
            height: 24px;
            width: 24px;
            top: 24px;
            right: 24px;
          }
        }
      }
    }
`



class SingleGalleryPage extends React.Component {

  constructor(props) {
      super(props);
      this.state = {
          thisGallery: this.props.data,
          isLightboxActive: false,
          lightboxImage: null,
          template: '1',
          viewPortHeight: '100vh',
          slides: []
      };
  }

  componentDidMount() {
    this.setViewPortHeight();
    this.setSlides();
  }

  setViewPortHeight() {
    const windowWidth = window.innerWidth;
    if (windowWidth > 768) {
      const heightOfTitleBar = document.getElementById('gallery__title-bar').offsetHeight;
      const halfHeight = heightOfTitleBar / 2;
      const finalHeight = halfHeight + 48;
      this.setState({ viewPortHeight: `calc(100vh + ${finalHeight}px)` });
    }
    
  }
  

  openLightbox = (image) => {
    this.setState({ isLightboxActive: true })
    this.setState({lightboxImage: <Img className="spotlight-image" 
                                        fluid={image.asset.fluid} 
                                        alt={image.src} 
                                        style={{ height: "100%", width: "100%" }}
                                        imgStyle={{ objectFit: "contain" }}
                                  /> 
    })
  }

  closeLightbox = () => {
    this.setState({ isLightboxActive: false })
    this.setState({lightboxImage: null })
  }

  setSlides = () => {
    const slideArray = []
    this.state.thisGallery.gallery.images.forEach(image => {
      slideArray.push( { 
        "type": "image",
        "data": image
       } )
    })

    if (this.state.thisGallery.gallery.vimeo_url) {
      slideArray.push({
        "type": "vimeo",
        "data": this.state.thisGallery.gallery.vimeo_url
      })
    }

    if (this.state.thisGallery.gallery.desktop_video.length) {
      slideArray.push({
        "type": "video",
        "data": this.state.thisGallery.gallery.desktop_video
      })
    }

    // console.log('SLIDE ARRY: ', slideArray)

    this.setState({ slides: slideArray })
    
  }

  render() {
    // console.log('THIS GALLERY', this.state.thisGallery)
    // console.log('SLIDES', this.state.slides)
    const gallery = this.state.thisGallery.gallery


    // const galleryImages = [];


    // this.state.thisGallery.gallery.images.forEach(image => {
    //   galleryImages.push(<div onClick={() => {this.openLightbox(image) } } key={image.asset._id} className="gallery-image"><Img fluid={image.asset.fluid} alt={image.src} /></div>)
    // })

    // const vimeoIframe = () => {
    //     return { __html: gallery.vimeo_url }
    // }

    let titlebarRoles = []

    this.state.thisGallery.gallery.role.forEach(role => {
      titlebarRoles.push(role.name)
    })

    let titlebarRolesJoined = titlebarRoles.join(', ')

    return (
      <>
        <Helmet>
          <title>{this.state.thisGallery.gallery.name} | Imogen Wilson</title>
          <script src="https://cdn.jsdelivr.net/npm/@mux/mux-player"></script>
        </Helmet>
        <Cursor 
          staticText={this.state.thisGallery.siteSettings.nodes[0].cursor_static}
          staticTextColor={this.state.thisGallery.siteSettings.nodes[0].cursor_static_color.hex}
          cursorSparkle={this.state.thisGallery.siteSettings.nodes[0].cursor_sparkle}
        />
        <HeaderAndMenu siteSettings={this.state.thisGallery.siteSettings.nodes[0]} roleData={this.state.thisGallery.roleData.nodes} page={'gallery'}/>
        <MyMarquee color={this.state.thisGallery.siteSettings.nodes[0].marquee_colour?.rgb} content={this.state.thisGallery.siteSettings.nodes[0].marquee_text}/>
          <GalleryPageStyles className="container" style={{ minHeight: this.state.viewPortHeight }}>
            <div className="gallery__title-bar" id="gallery__title-bar">
              <Link to="/galleries" className="back-button back-button--desktop">
                <div className="left-arrow"></div>
                <span>BACK</span>
              </Link>
              <div className="gallery-title">{this.state.thisGallery.gallery.name}</div>
              {/* <div className="gallery-role">{titlebarRolesJoined}</div> */}
              {
                this.state.thisGallery.gallery._rawCredits &&
                <BlockContent 
                  blocks={this.state.thisGallery.gallery._rawCredits} 
                  renderContainerOnSingleChild={true}
                  className="gallery-credits"
                />
              }
              
              <Link to="/galleries" className="back-button back-button--mobile">
                <div className="left-arrow"></div>
                <span>BACK TO GALLERIES</span>
              </Link>
            </div>


            <Swiper
              initialSlide={1}
              slidesPerView={1}
              centeredSlides={true}
              loop={true}
              navigation
              autoHeight={true}
              spaceBetween={16}
              lazy={{
                enabled: true,
                loadPrevNext: true
              }}
            >
              { this.state.slides.map( (slide, index) => 
                  {
                    if (slide.type == "image") {
                      const loadingType = index == 0 ? 'eager' : 'lazy'
                      return (
                      <SwiperSlide key={index + slide.data.asset._id} onClick={() => {this.openLightbox(slide.data) } }>
                        <Img fluid={slide.data.asset.fluid} 
                              style={{ height: "100%", width: "100%" }}
                              imgStyle={{ objectFit: "contain" }}
                              className="swiper-lazy"
                              loading={loadingType}
                        />
                      </SwiperSlide>
                      )
                      
                    }
                    else if (slide.type == "vimeo") {
                      return (
                        <SwiperSlide key={index}>
                          <div className="vimeo-slide" dangerouslySetInnerHTML={{ __html: slide.data }}></div>
                        </SwiperSlide>
                      )
                      
                    }
                    else if (slide.type == "video") {
                      if (slide.data[0].mux_video) {
                        return (
                          <SwiperSlide key={index}>
                            <div className="video-slide">
                            <mux-player
                              stream-type="on-demand"
                              playback-id={slide.data[0].mux_video.asset.playbackId}
                              metadata-video-title="Test VOD"
                              metadata-viewer-user-id="user-id-007"
                          ></mux-player>
                            </div>
                          </SwiperSlide>
                        )
                      }
                      else {
                        return (
                          <SwiperSlide key={index}>
                            <div className="video-slide">
                              <video controls>
                                <source src={slide.data[0].video_file.asset.url} type={slide.data[0].video_file.asset.mimeType} ></source>
                              </video>
                            </div>
                          </SwiperSlide>
                        )
                      }
                    }
                  }
                  
              ) }
              {/* { this.state.thisGallery.gallery.images.map( (image, index) => 
                  <SwiperSlide key={index + image.asset._id} onClick={() => {this.openLightbox(image) } }>
                    <Img fluid={image.asset.fluid} 
                          style={{ height: "100%", width: "100%" }}
                          imgStyle={{ objectFit: "contain" }}
                    />
                  </SwiperSlide>
              ) } */}
                {/* <div className="swiper-button-prev"></div>
                <div className="swiper-button-next"></div> */}
            </Swiper>
            <div className={ this.state.isLightboxActive ? 'lightbox lightbox__active' : 'lightbox' }>
              <div className="mask" onClick={() => {this.closeLightbox() }}>
              <div className="close-mask"></div>
              </div>
              <div className="lightbox__image" onClick={() => {this.closeLightbox() }}>
                {this.state.lightboxImage}
              </div>
            </div>
        </GalleryPageStyles>
        <Footer siteSettings={this.state.thisGallery.siteSettings.nodes[0]} />
      </>
    )
  }

}

export default SingleGalleryPage



export const query = graphql`
    query($slug: String!) {
        gallery: sanityGallery(slug: {
            current: { eq: $slug }
        }) {
            name
            images {
                asset {
                  _id
                    fluid(maxWidth: 600) {
                    ...GatsbySanityImageFluid_noBase64
                    }
                }
            }
            _rawCredits
            vimeo_url
            desktop_video {
              mux_video {
                asset {
                  playbackId
                }
              }
              video_file {
                asset {
                  url
                  mimeType
                }
              }
            }
            role {
              name
            }
            template_style
        }
        siteSettings: allSanitySiteSettings {
            nodes {
              logo {
                asset {
                  fluid(maxWidth: 800) {
                    ...GatsbySanityImageFluid_noBase64
                  }
                  extension
                  url
                }
              }
              menu_colour {
                hex
              }
              menu_text_colour {
                hex
              }
              menu_emoji_one
              menu_emoji_one_url
              menu_emoji_two
              menu_emoji_two_url
              menu_emoji_three
              menu_emoji_three_url
              marquee_colour {
                rgb {
                  a
                  b
                  g
                  r
                }
              }
              marquee_text  
              cursor_static
              cursor_sparkle
              cursor_static_color {
                hex
              }  
              mimi_casting_url
              instagram_url
              shop_url
              vimeo_url
              footer_mailto
              hover_gradient_one {
                hex
              }
              hover_gradient_two {
                hex
              }
              hover_gradient_three {
                hex
              }
            }
        }
        roleData: allSanityRole {
          nodes {
            name
            order
          }
        }
    }
`